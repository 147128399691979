
import Social from '../Social/Social';
import { LinkContainer, MobileMenuContainer, StyledButton, StyledLink } from "./MobileMenu.styles";

const MobileMenu = ({ refs, onRefsClick, showMenu }) => {
  return (
    <MobileMenuContainer className={showMenu ? 'open' : ''}>
      <LinkContainer className={showMenu ? 'open' : ''}>
        <StyledLink onClick={() => onRefsClick(refs.workshops)}>Creative writing workshops</StyledLink>
        <StyledLink onClick={() => onRefsClick(refs.storyProv)}>StoryProv</StyledLink>
        <StyledLink onClick={() => onRefsClick(refs.groups)}>Private & small group yoga</StyledLink>
        <StyledLink onClick={() => onRefsClick(refs.kids)}>Yoga for Kids</StyledLink>
        <StyledLink href='https://erinpettibooks.com'>Books</StyledLink>
      </LinkContainer>
      <Social />
      <StyledButton onClick={() => onRefsClick(refs.contact)} disableRipple disableFocusRipple variant="contained">Contact</StyledButton>
    </MobileMenuContainer>
  )
};

export default MobileMenu;