import { Button, TextField, styled } from "@mui/material";

export const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
});

export const FormField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& label': {
    color: theme.palette.common.black,
  },
  '& label.Mui-focused': {
    color: theme.palette.common.black,
  },
  '& .MuiFilledInput-root': {
    borderRadius: 0,
    '& fieldset': {
      borderColor: theme.palette.common.black,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.common.black,
    },
  }
}));

export const SubmitInput = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  backgroundColor: 'black',
  color: theme.palette.text.secondary,
  borderRadius: 0,
  padding: '1rem 3rem',
  marginTop: '3rem',
  textTransform: 'none',
  fontSize: '1.25rem',
  '&:hover': {
    backgroundColor: 'black',
    opacity: 0.8
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: 0,
    padding: '0.375rem 1rem',
  },
}));