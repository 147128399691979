import { CardContent, CardMedia, Typography } from "@mui/material";
import { OfferCard } from "./OfferingCard.styles";

const OfferingCard = ({ title, subtitle, description, image }) => {
  return (
    <OfferCard>
        <CardMedia
          component="img"
          height="460"
          image={image}
          alt={title}
        />
        <CardContent>
          <Typography gutterBottom sx={{paddingTop: '2rem', textAlign: 'center'}} variant="h4" component="div">
            {title}
          </Typography>
          {subtitle && <Typography sx={{textAlign: 'center', fontFamily: 'Nanum Myeongjo, serif', '& a': { color: 'text.primary' }}} color="text.primary" dangerouslySetInnerHTML={{__html: subtitle}} />}
          <Typography sx={{fontSize: '1.25rem'}} color="text.primary" dangerouslySetInnerHTML={{__html: description}} />
        </CardContent>
    </OfferCard>
  );
};

export default OfferingCard;