import { styled } from "@mui/material";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

export const MobileMenuContainer = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4rem',
  justifyContent: 'flex-start',
  alignItems: 'left',
  padding: '13rem 2rem',
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.primary.main,
  position: 'fixed',
  zIndex: '100',
  top: '0',
  left: '0',
  opacity: '0',
  transform: 'translateY(-100%)',
  transition: 'translateY 0.3s ease-in-out, opacity 0.3s ease-in-out, gap 0.3s ease-in-out',
  '&.open': {
    opacity: '1',
    gap: '3rem',
    transform: 'translateY(0)',
  }
}));

export const LinkContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  transition: 'gap 0.3s ease-in-out',
  '&.open': {
    gap: 0,
  }
});

export const StyledLink = styled(Link)(({ showMenu, theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '2rem',
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
    opacity: 0.8
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'black',
  color: theme.palette.text.secondary,
  borderRadius: 0,
  textTransform: 'none',
  padding: '1.25rem 2rem',
  fontSize: '1.4rem',
  width: '128px',
  '&:hover': {
    backgroundColor: 'black',
    opacity: 0.8
  },
}));