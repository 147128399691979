import { styled } from "@mui/material";

export const HeroGridContainer = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down(1200)]: {
    display: 'flex',
  flexDirection: 'column-reverse'
  },
}));

export const HeroImage = styled('img')({
  position: 'relative'
})

export const CopyContainer = styled('article')(({ theme }) => ({
  position: 'absolute',
  gridArea: 'copy',
  left: '50%',
  padding: '0 2rem',
  transform: 'translate(-50%, -50%)',
  [theme.breakpoints.down(1200)]: {
    position: 'relative',
    transform: 'translate(0, 0)',
    left: 0,
    padding: '1rem 2rem'
  },
  '& p': {
    fontSize: '1.4rem',
    margin: '0.5rem 0',
    [theme.breakpoints.down(1600)]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down(1400)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: '1.4rem',
    }
  },
  zIndex: '2',
}));

export const CopyFlexBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const Descriptors = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontStyle: 'italic',
  fontSize: '1.6rem',
  marginTop: '0',
  [theme.breakpoints.down(1600)]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.down(1200)]: {
    fontSize: '1.6rem',
  }
}));

export const Hi = styled('img')(({ theme }) => ({
  width: '50%',
  margin: '0 auto',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
   width: '100%'
  }
}));
