import { useState } from 'react';
import { Form, FormField, SubmitInput } from './ContactForm.styles';

const statuses = {
  notSent: 'Send',
  pending: 'Sending...',
  done: 'Email sent.',
  error: "Oops! That didn't work.",
};

export const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(statuses.notSent);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (status !== statuses.notSent) return;
    setStatus(statuses.pending);
    const { name, email, message, subject } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      subject: subject.value,
      message: message.value,
    };
    let response = await fetch(process.env.REACT_APP_MAILER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_MAILER_JWT}`,
      },
      body: JSON.stringify(details),
    });
    response.ok ? setStatus(statuses.done) : setStatus(statuses.error);
  };

  return (
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FormField
          variant='filled'
          onChange={(e) => setName(e.target.value)}
          type='text'
          id='name'
          value={name}
          required={true}
          label='Name'
        />
        <FormField
          variant='filled'
          onChange={(e) => setEmail(e.target.value)}
          type='email'
          id='email'
          value={email}
          required={true}
          label='Email address'
        />
        <FormField
          variant='filled'
          onChange={(e) => setSubject(e.target.value)}
          type='text'
          id='subject'
          value={subject}
          required={true}
          label='Subject'
        />
        <FormField
          variant='filled'
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows={6}
          type='text'
          id='message'
          value={message}
          required={true}
          label='Message'
        />
        <div>
          <SubmitInput
            variant="contained"
            disableRipple
            disableFocusRipple
            type='submit'
          >
            {status}
          </SubmitInput>

          {status === statuses.done ? (
            <p>Thanks for getting in touch!</p>
          ) : status === statuses.error ? (
            <p>Try refreshing the page and submitting again.</p>
          ) : null}
        </div>
      </Form>
  );
};
