import { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CopyContainer, CopyFlexBox, Descriptors, HeroGridContainer, HeroImage, Hi } from './HeroGrid.styles';
import hi from '../../../assets/hi.png';
import hero from '../../../assets/hero-grid-bg.jpg';
import heroSmall from '../../../assets/hero-small.jpg'

const HeroGrid = () => {
	useEffect(() => {
		const handleResize = () => {
		  const viewportWidth = window.innerWidth;
		  const copyElement = document.getElementById('copy');
		  if(copyElement) {
        if(viewportWidth >= 1800) {
          copyElement.style.top = `658px`;
        } else if(viewportWidth >= 1600) {
          copyElement.style.top = `610px`;
        } else if(viewportWidth >= 1400) {
          copyElement.style.top = `575px`;
        } else if(viewportWidth > 1300) {
          copyElement.style.top = `536px`;
        } else if(viewportWidth > 1200) {
			copyElement.style.top = `506px`;
		  } else { copyElement.style.top = 0; }
		  };
		};
	
		handleResize(); // Call the function initially to set the initial height
	
		window.addEventListener('resize', handleResize); // Add event listener for window resize
	
		return () => {
		  window.removeEventListener('resize', handleResize); // Clean up the event listener on component unmount
		};
	  }, []);

    const sub1200 = useMediaQuery('(max-width:1200px)');

	return (
		<HeroGridContainer>
			<CopyContainer id='copy'>
				<CopyFlexBox sx={{display: 'flex', flexDirection: 'column',}}>
          <Hi src={hi} alt="Hi, I'm Erin" />
          <Descriptors>~ Children's book author, word nerd, yoga witch ~</Descriptors>
          <p>My mission is to help kids discover and amplify their creative voices. Everyone has a story to tell. The work of finding, shaping, and sharing that story is a joyful, and incredibly powerful thing.</p>
				</CopyFlexBox>
			</CopyContainer>
			<HeroImage src={sub1200 ? heroSmall : hero} width='100%'/>
		</HeroGridContainer>
	)
};

export default HeroGrid;