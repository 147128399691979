import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Container  = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '-1.5rem',
  }
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nanum Myeongjo, serif',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '4rem',
  }
}));

const The = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  marginBottom: '-1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '-0.75rem'
  }
}));

const CWP = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  textAlign: 'right',
  marginTop: '-1.5rem',
  marginRight: '-2.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginTop: '-0.75rem',
    marginRight: '-2rem'
  }
}));

const StoryProvTitle = () => {
  return (
    <Container>
      <The>The</The>
      <Heading variant="h1">StoryProv</Heading>
      <CWP sx={{ textAlign: 'right', marginTop: '-1.5rem', marginRight: '-2.5rem'}}>Creative Writing Method</CWP>
    </Container>
  )
};

export default StoryProvTitle;