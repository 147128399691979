
import { createTheme } from '@mui/material/styles';

const unsetMuiDefault = undefined;

const theme = createTheme({
  palette: {
    primary: {
      light: 'white',
      main: '#EEEDEB',
      dark: '#E1CCBE',
    },
    secondary: {
      main: '#EEEDEB',
    },
    text: {
      primary: '#000000',
      secondary: '#E1CCBE',
      disabled: unsetMuiDefault,

    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  typography: {
    fontFamily: 'EB Garamond, serif',
  }
});

export default theme;
