import { styled } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';

const headerSizeProperties = {
    height: '20vw',
    maxWidth: 286,
    maxHeight: 286,
    minWidth: 192,
    minHeight: 192
  }

  export const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    padding: '0 2rem',
    transition: 'height 0.5s ease-in-out',
    boxShadow: 'none',
    minHeight: 192,
    maxHeight: 192,
}));
  
  export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto 1fr',
      justifyItems: 'end'
    }
  }));
  
  export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }));
  
  export const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      opacity: 0.8
    },
  }));
  
  export const StyledNav = styled('nav')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }));

  export const LogoButton = styled('button')(({ theme }) => ({
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }));
  
  export const StyledLogo = styled('img')({
    padding: '1rem',
    transition: 'all 0.5s ease-in-out',
    ...headerSizeProperties
  });
  
  export const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'black',
    color: theme.palette.text.secondary,
    borderRadius: 0,
    textTransform: 'none',
    padding: '1.25rem 2rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'black',
      opacity: 0.8
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }));
  
  export const SocialContactContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }));
  
  export const Offset = styled('div')(({theme}) => ({
    ...headerSizeProperties
  }));