import { useRef, useState } from "react";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Content from "../Content/Content";
import Header from "../Header/Header";
import { usePrefersReducedMotion } from "../../hooks/usePrefersReducedMotion";

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  const handleMenuClick = () => { 
    setShowMenu(!showMenu);
  };

  const workshopsRef = useRef(null);
  const groupsRef = useRef(null);
  const kidsRef = useRef(null);
  const contactRef = useRef(null);
  const topRef = useRef(null);
  const storyProvRef = useRef(null);

  const refs = {
    workshops: workshopsRef,
    groups: groupsRef,
    kids: kidsRef,
    contact: contactRef,
    top: topRef,
    storyProv: storyProvRef
  }

  const onRefsClick = (ref) => {
    ref.current.scrollIntoView({ behavior: prefersReducedMotion ? 'instant' : 'smooth' });
    setShowMenu(false);
  };

  return (
    <StyledContainer>
      <Header refs={refs} handleMenuClick={handleMenuClick} onRefsClick={onRefsClick} showMenu={showMenu}/>
      <Content refs={refs} onRefsClick={onRefsClick} showMenu={showMenu}/>    
    </StyledContainer>
  )
}

export default Home;