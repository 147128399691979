import elementary from '../../assets/elementary.jpg';
import yoga from '../../assets/yoga-erin-practice.jpg';
import pose from '../../assets/yoga-partner-pose.jpeg';
import om from '../../assets/om.jpg';
import published from '../../assets/published.jpg';
import toddle from '../../assets/toddle.jpg';
import spScene from '../../assets/storyprov-scene.jpg';

import { Copy, PrimaryContainer, SecondaryContainer, StyledButton, Heading, Quote, SecondaryCopy, SectionImage, MainQuote, MainQuoteContainer, SectionImageSquare, CardContainer, CardHeading, CardSection, ContactContainer, ContactBox, ResearchLink, StoryProvContainer, StoryProvCopy, StoryProvLink, MainContainer, StoryProvLinks, LaunchIcon } from "./Content.styles";
import { Box, Typography, useTheme } from "@mui/material";
import OfferingCard from "./OfferingCard/OfferingCard";
import { ContactForm } from "./ContactForm/ContactForm";
import Social, { SocialIconSizes } from "../Social/Social";
import HeroGrid from "./HeroGrid/HeroGrid";
import MobileMenu from "../MobileMenu/MobileMenu";
import StoryProvTitle from './StoryProvTtitle/StoryProvTitle';

const Content = ({ refs, onRefsClick, showMenu }) => {
  const theme = useTheme();

  const { workshops: workshopsRef, groups: groupsRef, kids: kidsRef, contact: contactRef, top: topRef, storyProv: storyProvRef } = refs;

  return (
    <MainContainer>
      <MobileMenu refs={refs} onRefsClick={onRefsClick} showMenu={showMenu} />
      <PrimaryContainer ref={topRef} padding={'0'} scrollMarginTop={'12rem'}>
        <HeroGrid />
      </PrimaryContainer>
      <SecondaryContainer ref={workshopsRef}>
        <Copy>
          <em>Ages 8 and up</em>
          <Heading sx={{ padding: '1.5rem 0' }} variant="h2">Creative Writing Workshops</Heading>
          <Quote>
            <p>"We truly believe that Erin’s visit will have a long lasting impact on our student’s approach to storytelling. We look forward to inviting her back to our school for many years to come."</p>
            <cite>— Jessica Shaw, Halifax Elementary School</cite>
          </Quote>
          <SecondaryCopy>What makes a good story? In this signature workshop, students begin to answer that question by building a world of their own. This hour-long class session is designed to spark inspiration and creativity.</SecondaryCopy>
          <SecondaryCopy sx={{marginTop: 0}}>Customized multi-session "Write Your Own Book" workshops are also available for schools and libraries.</SecondaryCopy>
          <StyledButton onClick={() => onRefsClick(refs.contact)} disableRipple disableFocusRipple variant="contained">Inquire about creative writing workshops</StyledButton>
        </Copy>
        <SectionImage src={elementary} alt='Erin at a elementary classroom visit' />
      </SecondaryContainer>
      <StoryProvContainer ref={storyProvRef}>
        <Typography variant="h3" component="p" sx={{ alignSelf: 'center' }}>Coming Spring 2024</Typography>
        <StoryProvTitle />
        <StoryProvCopy bgUrl={spScene} >
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant='h5' component='p'>The StoryProv Creative Writing Method offers the mindful integration of improvisational play, yoga technique, and creative writing. A dynamic approach to unlocking children’s creativity and confidence, StoryProv builds classroom community and peer support through creative play, while strengthening the individual student's connection to self, intuition, and ultimately, the writer’s voice.</Typography>
          </Box>
          <SectionImage src={spScene} alt='Two young creators acting out an improv scene' />
        </StoryProvCopy>
        <Quote sx={{textAlign: 'center'}}>
          <Typography variant='h5' component='p'>Imagination + Empathy + Bravery = Great Storytelling.</Typography>
          <SecondaryCopy>Also -  an even more supportive and creative classroom culture.</SecondaryCopy>
        </Quote>
        <StoryProvLinks>
          <StyledButton sx={{alignSelf: 'center' }} onClick={() => onRefsClick(refs.contact)} disableRipple disableFocusRipple variant="contained">Schedule your program</StyledButton>
          <Typography variant="h5" sx={{textAlign: 'center', marginTop: '2rem', paddingBottom: 0}}>Research Notes: A little bit of what’s been written…</Typography>
          <h3>Why Improv?</h3>
          <StoryProvLink><LaunchIcon /><ResearchLink target='_blank' rel='noreferrer noopener' href='https://www.frontiersin.org/articles/10.3389/fpsyg.2021.638932/full'>Frontiers in Psychology: Teenagers Tell Better Stories After Improvisational Theater Courses</ResearchLink></StoryProvLink>
          <StoryProvLink><LaunchIcon /><ResearchLink target='_blank' rel='noreferrer noopener' href='http://www.ijea.org/v10n12/'>International Journal of Education and the Arts: Remembering to Laugh and Explore: Improvisational Activities for Literacy Teaching in Urban Classrooms</ResearchLink></StoryProvLink>
          <h3>Why Yoga?</h3>
          <StoryProvLink><LaunchIcon /><ResearchLink target='_blank' rel='noreferrer noopener' href='https://www.health.harvard.edu/blog/more-than-just-a-game-yoga-for-school-age-children-201601299055'>Harvard Health Publishing: More than just a game: Yoga for school-age children</ResearchLink></StoryProvLink>
          <StoryProvLink><LaunchIcon /><ResearchLink target='_blank' rel='noreferrer noopener' href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3980104/'>Frontiers in Psychiatry: Yoga for Children and Young People’s Mental Health and Well-Being: Research Review and Reflections on the Mental Health Potentials of Yoga</ResearchLink></StoryProvLink>
        </StoryProvLinks>
      </StoryProvContainer>
      <SecondaryContainer ref={groupsRef} order={'row-reverse'}>
        <Copy bgColor={theme.palette.primary.light}>
          <em>Plan your practice</em>
          <Heading variant="h2">Private and Group Classes</Heading>
          <SecondaryCopy>Available for any age, any level.</SecondaryCopy>
          <SecondaryCopy>Take the time you need. Build your confidence. Go deeper. Find joy in the flow.</SecondaryCopy>
          <SecondaryCopy>60 minutes of mindful movement and breath for individuals or small groups, customized to your needs or focus.</SecondaryCopy>
          <p><StyledButton onClick={() => onRefsClick(refs.contact)} disableRipple disableFocusRipple variant="contained">Book a private or small group class</StyledButton></p>
        </Copy>
        <SectionImage src={yoga} alt='Erin practicing yoga' />
      </SecondaryContainer>
      <PrimaryContainer>
        <MainQuoteContainer>
          <MainQuote variant='h2' component='p'>Joy is not made to be a crumb.</MainQuote>
          <cite>- Mary Oliver</cite>
        </MainQuoteContainer>
      </PrimaryContainer>
      <SecondaryContainer ref={kidsRef} bgColor={theme.palette.primary.main}>
        <Copy>
          <Heading variant="h2">Yoga for Kids</Heading>
          <SecondaryCopy>Joyful movement, imagination, story, song, and creativity! It’s my absolute pleasure to bring yoga to kids of all ages. Whether it’s stomping like dinos with toddlers or learning breathing exercises for stress relief with middle schoolers – yoga has a tremendous positive impact on growing kids. Also, it’s a super fun way for kids to develop intuitive, happy, peaceful relationships with their bodies. </SecondaryCopy>
          <SecondaryCopy>Yoga for the classroom, scout groups, and homeschool co-ops. Get in touch!</SecondaryCopy>
        </Copy>
        <SectionImageSquare src={pose} alt='Kids doing heart pose' />
      </SecondaryContainer>
      <CardSection bgColor={theme.palette.primary.light}>
        <CardHeading variant="h3">A little more info...</CardHeading>
        <CardContainer>
          <OfferingCard
            image={published}
            title="Books and Customized Writing Workshops"
            subtitle="Visit <a href='https://www.erinpettibooks.com' rel='noopener noreferrer' target='_blank'>erinpettibooks.com</a> for tons of information on my published books!"
            description="<p>If you are a teacher or librarian who wants to create a dynamic, supportive, and vibrant creative space for kids to find their creative voice - I truly hope you get in touch. Let’s work together to make a “Write your own book” workshop that will infuse young authors with the confidence to alchemize their big dreams into real words.</p>"  
          />
          <OfferingCard
            image={om}
            title="Your Personal Yoga Path"
            description="<p>60 minutes of mindful movement and breath for individuals or small groups, customized to your needs or focus.</p><p>Personal Yoga Coaching: In this series of individual sessions, we will create a lasting personal practice. Utilizing any and all modalities in the yoga toolbox, we will work together to get to build a daily or weekly routine that can grow with you.</p>"  
          />
          <OfferingCard
            image={toddle}
            title="Yoga for Kids"
            description="<p>Joyful movement, imagination, story, song, and creativity!</p><p>Yoga for the classroom, scout groups, and homeschool co-ops.</p>"  
          />
        </CardContainer>
      </CardSection>
      <PrimaryContainer ref={contactRef} scrollMarginTop={'9rem'}>
        <ContactContainer>
          <ContactBox>
            <Heading variant="h2">Contact me.</Heading>
            <Social size={SocialIconSizes.large}/>
          </ContactBox>
          <ContactBox>
            <ContactForm />
          </ContactBox>
        </ContactContainer>
      </PrimaryContainer>
    </MainContainer>
    )
};

export default Content;