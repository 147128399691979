import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Launch from '@mui/icons-material/Launch';

export const MainContainer = styled('main')({
  display: 'flex',
  flexDirection: 'column',
});

export const PrimaryContainer = styled('section')(({ theme, bgColor, padding, scrollMarginTop }) => ({
  backgroundColor: bgColor || theme.palette.primary.dark,
  maxWidth: '100%',
  width: '100%',
  padding: padding || '0 5rem 5rem',
  scrollMarginTop,
  [theme.breakpoints.down('lg')]: {
    padding: padding || '0 1rem 3rem'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 0 3rem'
   }
})); 

export const SecondaryContainer = styled('section')(({ theme, bgColor, order }) => ({
  backgroundColor: bgColor || theme.palette.primary.light,
  flexDirection: order || 'row',
  maxWidth: '100%',
  width: '100%',
  padding: '5rem',
  display: 'flex',
  gap: '5rem',
  scrollMarginTop: '9rem',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '1.5rem',
    padding: '0 0 3rem'
  }
})); 

export const StoryProvContainer = styled('section')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  scrollMarginTop: '7rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '5rem 18rem',
  gap: '2rem',
  [theme.breakpoints.down('1400')]: {
    padding: '2rem'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 1rem'
  }
}));

export const StoryProvCopy = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgUrl',
})(({ bgUrl, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '2rem',
  width: '100%',
  [theme.breakpoints.down('900')]: {
    flexDirection: 'column-reverse',
  }
}));

export const StoryProvLinks = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0',
  width: '100%',
});

export const StoryProvLink = styled(Box)(({ theme }) => ({ 
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
}));

export const LaunchIcon = styled(Launch)({
  marginTop: '0.3rem'
})

export const Copy = styled('article')(({ theme, bgColor }) => ({
  gridArea: 'copy',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '3rem 2rem 2rem',
  backgroundImage: 'none',
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 1rem 0',
    backgroundColor: bgColor || theme.palette.primary.main 
  }
}));


export const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nanum Myeongjo, serif',
}));

export const Quote = styled('blockquote')({
  fontSize: '1.5rem',
  padding: '1rem 3rem',
  margin: '0',
  backgroundColor: '#ccc',
  '& p': {
    fontStyle: 'italic',
    margin: '0',
  },
  '& cite': {
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'right',
    padding: '0 3rem',
    margin: '0',
  }
});

export const SecondaryCopy = styled('p')({
  fontSize: '1.25rem',
});

export const ResearchLink = styled(Link)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: '300px',
  backgroundColor: 'black',
  color: theme.palette.primary.main,
  borderRadius: 0,
  textTransform: 'none',
  fontSize: '1.25rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '0',
    maxWidth: '100%'
  },
  '&:hover': {
    backgroundColor: 'black',
    opacity: 0.8
  },
}));

export const SectionImage = styled('img')(({ theme }) => ({
  width: '50%',
  height: '50%',
  maxWidth: '600px',
  borderRadius: '50%',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    borderRadius: '0',
    padding: '0 1rem'
  }
}));

export const SectionImageSquare = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  alignSelf: 'center',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    padding: '0 1rem'
  }
}));

export const MainQuoteContainer = styled('article')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '8rem',
  gap: '1rem',
  '& cite': {
    fontSize: '1.25rem',
    marginTop: '1.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '2rem',
  }
}));

export const MainQuote = styled(Typography)({
  paddingTop: '5rem',
  fontFamily: 'Nanum Myeongjo, serif',
  '& cite': {
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'right',
    padding: '0 3rem',
    margin: '0',
  }
});

export const CardSection = styled('section')(({ theme, bgColor }) => ({
  backgroundColor: bgColor || theme.palette.primary.light,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  maxWidth: '100%',
  paddingBottom: '5rem',
}));

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  padding: '0 5rem',
  maxWidth: '100%',
  [theme.breakpoints.down('lg')]: {
    padding: '0 1rem',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: 0
  }
}));

export const CardHeading = styled(Typography)({
  fontFamily: 'Nanum Myeongjo, serif',
  padding: '5rem 2rem',
  alignSelf: 'center',
});

export const ContactContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '5rem',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    padding: '3rem 0'
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem'
  },
}));

export const ContactBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingTop: '2rem'
  },
}));

  