import { useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/logo-with-birds-trimmed.png';
import Social from '../Social/Social';
import { LogoButton, Offset, SocialContactContainer, StyledAppBar, StyledButton, StyledIconButton, StyledLink, StyledLogo, StyledNav, StyledToolbar } from './Header.styles';


const Header = ({ refs, handleMenuClick, onRefsClick, showMenu }) => {

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('header');
      const logo = document.getElementById('logo');
      if (window.scrollY > 50 && header && logo) {
        header.style.height = `192px`;
        logo.style.height = `100px`;
      }
      if (window.scrollY < 50 && header && logo) {
        header.style.height = `20vw`;
        logo.style.height = `20vw`;
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box>
      <StyledAppBar id="header" position="fixed">
        <StyledToolbar>
          <StyledNav>
            <StyledLink onClick={() => onRefsClick(refs.workshops)}>Creative writing workshops</StyledLink>
            <StyledLink onClick={() => onRefsClick(refs.storyProv)}>StoryProv</StyledLink>
            <StyledLink onClick={() => onRefsClick(refs.groups)}>Private & small group yoga</StyledLink>
            <StyledLink onClick={() => onRefsClick(refs.kids)}>Yoga for Kids</StyledLink>
            <StyledLink href='https://erinpettibooks.com'>Books</StyledLink>
          </StyledNav>
          <LogoButton onClick={() => onRefsClick(refs.top)}>
            <StyledLogo id="logo" src={logo} alt='Pens and Poses logo' />
          </LogoButton>
          <SocialContactContainer>
            <Social leftGutter/>
            <StyledButton onClick={() => onRefsClick(refs.contact)} disableRipple disableFocusRipple variant="contained">Contact</StyledButton>
          </SocialContactContainer>
          <StyledIconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => handleMenuClick()}
          >
            <MenuIcon sx={{display: showMenu ? 'none' : 'block' }}/>
            <CloseIcon sx={{display: showMenu ? 'block' : 'none' }}/>
          </StyledIconButton>
        </StyledToolbar>
      </StyledAppBar>
      <Offset />
    </Box>
  )
};

export default Header;