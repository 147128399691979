import { Card, styled } from "@mui/material";

export const OfferCard = styled(Card)(({ theme }) => ({
  minWidth:'275',
  width:'33%',
  boxShadow: 'none',
  borderRadius: 'unset',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));